
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    items: Array,
  },
  data() {
    return {};
  },
})
export default class GroupMatrix extends Vue {}
